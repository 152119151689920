import { InternalTicket } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { useHistory } from 'react-router-dom';
import ServiceGroupingDetails from '../../pages/site-services/ServiceGroupingDetails/ServiceGroupingDetails';
import { routes } from '../../utils';
import CustomerDetails from '../CustomerDetails';
import HaulerDetails from '../HaulerDetails';
import LeadDetails from '../LeadDetails';
import OrderDetails from '../OrderDetails';

type DrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    ticket?: InternalTicket.SAPInternalTicketTransport;
};

export const DetailsDrawer: React.FC<DrawerProps> = ({ isOpen, onClose, ticket }) => {
    const history = useHistory();

    const getContent = (ticketInfo?: InternalTicket.SAPInternalTicketTransport) => {
        if (!ticketInfo) return null;
        switch (ticketInfo.entityType) {
            case 'sourgum-order':
                return <OrderDetails id={ticketInfo.entityID} />;
            case 'sourgum-customer':
                return <CustomerDetails id={ticketInfo.entityID} />;
            case 'sourgum-service-grouping':
                return <ServiceGroupingDetails serviceGroupingId={ticketInfo.entityID} />;
            case 'sourgum-lead':
                return <LeadDetails id={ticketInfo.entityID} />;
            case 'sourgum-vendor':
                return <HaulerDetails id={ticketInfo.entityID} />;
            default:
                return <div>Missing entity type</div>;
        }
    };

    const handleFullDetailsNavigate = () => {
        if (!ticket) return;
        let route: string | null = null;
        switch (ticket.entityType) {
            case 'sourgum-order':
                route = routes.orders.details(ticket.entityID);
                break;
            case 'sourgum-customer':
                route = routes.customers.details(ticket.entityID);
                break;
            case 'sourgum-service-grouping':
                route = routes.universalServices.serviceGrouping.details(ticket.entityID);
                break;
            case 'sourgum-lead':
                route = routes.leads.details(ticket.entityID);
                break;
            case 'sourgum-vendor':
                route = routes.haulers.details(ticket.entityID);
                break;
            default:
                break;
        }
        if (route) history.push(route);
    };

    return (
        <>
            {/* Overlay */}
            <div className={`fixed inset-0 z-40 bg-black opacity-50 ${isOpen ? 'block' : 'hidden'}`} onClick={onClose}></div>

            {/* Drawer */}
            <div
                className={`fixed right-0 top-0 z-50 h-full w-4/5 transform bg-slate-50 p-3 transition-transform ${
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                } overflow-auto`}
            >
                <div className="mb-4 flex justify-end">
                    <Button
                        className="btn-secondary-outlined"
                        endIcon={<ArrowTopRightOnSquareIcon className="size-5" />}
                        onClick={handleFullDetailsNavigate}
                    >
                        To Full Details
                    </Button>
                </div>
                <div className="flex flex-col gap-y-3">{getContent(ticket)}</div>
            </div>
        </>
    );
};
