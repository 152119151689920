import { useWaysteClient } from '@alliance-disposal/client';
import { DurationUnitLabels, UniversalService } from '@alliance-disposal/transport-types';
import { formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import CardInfoList from '../../../components/CardInfoList';
import DetailsCardWrapper from '../../../components/DetailsCardWrapper';
import { routes } from '../../../utils';

const RFPDetailsCard = ({ rfp }: { rfp: UniversalService.RFPTransport }) => {
    const client = useWaysteClient();
    const history = useHistory();
    const userIDToNameMap = client.profile().adminPortal.getRosterFromMemory().idToProfileMap;

    const getSubmissionStatus = () => {
        if (!rfp.rfpVendorResponses || rfp.rfpVendorResponses.length === 0) {
            return 'Not sent to haulers';
        }
        const totalLength = rfp.rfpVendorResponses.length;
        const submittedLength = rfp.rfpVendorResponses.filter((response) => response.status === 'SUBMITTED').length;
        return `${submittedLength} of ${totalLength} submitted`;
    };

    const colOne = [
        { label: 'Name', value: rfp.rfpName },
        { label: 'Service Address', value: formatServiceAddress(rfp.serviceLocation.address) },
        { label: 'County', value: rfp.serviceLocation.county },
        { label: 'Business Type', value: rfp.businessType || '' },
        { label: 'Target Rate', value: rfp.targetProposalAmount ? moneyFormatter(rfp.targetProposalAmount) : '' },
        { label: 'Proposal Number', value: rfp.proposalNumber },
        {
            label: 'Status',
            value: (
                <span className={rfp.status === 'VOID' ? 'text-red-500' : rfp.status === 'AWARD' ? 'text-green-500' : ''}>
                    {UniversalService.RFPStatusLabels[rfp.status]}
                </span>
            ),
        },
        { label: 'Deadline', value: format(new Date(rfp.deadlineDate), 'E MM/dd/yyyy h:mm aaa') },
        {
            label: 'Billing Frequency',
            value: rfp.rfpSkus[0]?.billingFrequency
                ? `${rfp.rfpSkus[0].billingFrequency.value} x ${DurationUnitLabels[rfp.rfpSkus[0].billingFrequency.unit].singular}`
                : '',
        },
        {
            label: 'Submission Status',
            value: getSubmissionStatus(),
        },
    ];

    const colTwo = [
        { label: 'From lead', value: rfp.forLeadID ? <Link to={routes.leads.details(rfp.forLeadID)}>To lead</Link> : 'No' },
        { label: 'From customer', value: rfp.forCustomerID ? <Link to={routes.leads.details(rfp.forCustomerID)}>To customer</Link> : 'No' },
        { label: 'Created By', value: userIDToNameMap[rfp.metadata.createdByUserID]?.fullName },
        {
            label: 'Created',
            value: format(new Date(rfp.metadata.createdAt), 'EEE MM/dd/yy h:mm aaa'),
        },
        { label: 'Last Updated By', value: userIDToNameMap[rfp.metadata.lastUpdatedByUserID]?.fullName },
        {
            label: 'Updated',
            value: rfp.metadata.lastUpdatedAt ? format(new Date(rfp.metadata.lastUpdatedAt), 'EEE MM/dd/yy h:mm aaa') : '',
        },
    ];

    return (
        <DetailsCardWrapper
            heading={`RFP #${rfp.proposalNumber}`}
            className={rfp.status === 'VOID' ? 'bg-red-50' : ''}
            buttons={[
                {
                    label: 'Update RFP',
                    onClick: () => history.push(routes.requestForProposal.update(rfp.id)),
                    disabled: rfp.status !== 'OPEN',
                },
            ]}
        >
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <CardInfoList data={colOne} border />
                <CardInfoList data={colTwo} />
                <div className="lg:col-span-2">
                    <hr />
                    <div className="flex flex-col">
                        <div className="py-1 font-bold underline">Skus</div>
                        {rfp.rfpSkus.map((sku) => (
                            <div key={sku.id} className="flex">
                                <div>
                                    ({sku.quantity}) {sku.serviceType.name} -{' '}
                                    {sku.serviceFrequency
                                        ? sku.serviceCycleLength === 2
                                            ? '1x Every other week'
                                            : `${sku.serviceFrequency.value} x ${DurationUnitLabels[sku.serviceFrequency.unit].singular}`
                                        : 'One time'}
                                </div>
                                <div className="px-2">|</div>
                                <div className="pr-1">{sku?.rfpSkuExtras && sku?.rfpSkuExtras?.length > 0 ? 'Addons - ' : ''}</div>
                                <div>
                                    {sku.rfpSkuExtras?.map(
                                        (item, index) =>
                                            item.serviceLineItemType.displayName +
                                            (sku.rfpSkuExtras && index === sku.rfpSkuExtras?.length - 1 ? '' : ', '),
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </DetailsCardWrapper>
    );
};

export default RFPDetailsCard;
