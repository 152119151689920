import React, { useContext, useState } from 'react';
import { Invoice } from '@alliance-disposal/transport-types';
import { InternalTicket } from '@wayste/sour-components';
import { Tab, TabPanel, Tabs } from '@wayste/sour-ui';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { useHistory } from 'react-router-dom';
import { ContactsListTable } from '../../../../components/ContactsListCard/ContactsListCard';
import CustomerDetailsLists from '../../../../components/CustomerDetailsLists';
import OrderBillingDetailsLists from '../../../../components/OrderBillingDetailsLists';
import OrderDetailsLists from '../../../../components/OrderDetailsLists';
import { routes } from '../../../../utils';
import { BillingContext } from '../../context';

const styles: Record<string, React.CSSProperties> = {
    topBar: {
        padding: '8px 25px 8px 15px',
        borderTop: 'solid 1px rgba(0, 0, 0, 0.48)',
        borderLeft: 'solid 1px rgba(0, 0, 0, 0.48)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#F2F2F2',
    },
    bottomBarIcon: {
        width: 16,
        height: 16,
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        padding: 1,
        justifyContent: 'flex-end',
    },
    bottomBarIconActive: {
        backgroundColor: 'rgba(0, 122, 255, 0.68)',
        borderColor: 'rgba(0, 122, 255, 1)',
    },
    bottomBarIconInactive: {
        backgroundColor: 'rgba(0, 0, 0, 0.68)',
        borderColor: 'rgba(0, 0, 0, 1)',
    },
    bottomBarIconInside: {
        background: '#FFFFFF',
        width: '100%',
    },
    bottomBarBody: {
        backgroundColor: '#FFFFFF',
        width: '100%',
        borderLeft: 'solid 1px rgba(0, 0, 0, 0.48)',
        overflow: 'auto',
    },
};

const openStateOptions = [0, 1, 2];
const tabOptions = ['Order', 'Customer', 'Billing'];

interface Props {
    receivables: Invoice.ReceivableTransport[] | null;
    payables: Invoice.PayableTransport[] | null;
}

const DetailsBar = ({ payables, receivables }: Props) => {
    const { customer, selectedOrder, selectedOrderType } = useContext(BillingContext);
    const history = useHistory();
    const [openState, setOpenState] = useState(0);

    const onShowDetails = () => {
        if (selectedOrderType === 'roll-off') {
            history.push(routes.orders.details(selectedOrder?.id || ''), { modal: true });
        } else if (selectedOrderType === 'universal-service') {
            console.log('selectedOrder: ', selectedOrder);
            history.push(routes.universalServices.serviceGrouping.details(selectedOrder?.serviceOrder?.serviceGroupingID || ''));
        }
    };

    return (
        <div style={{ position: 'sticky', zIndex: 99, bottom: 0, width: '100%' }}>
            {/* Top of bottom bar */}
            <div style={styles.topBar}>
                <div>
                    <span>Order Details</span> #
                    {selectedOrder
                        ? `${selectedOrder.orderNumber} - ${
                              selectedOrder.vendorID ? (selectedOrder.vendorName ?? 'Missing Vendor Name') : 'Not Assigned to Vendor'
                          }`
                        : null}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {openStateOptions.map((item, index) => {
                        let wrapperStyle = {
                            ...styles.bottomBarIcon,
                            margin: '0 .25rem',
                        };
                        index === openState
                            ? // @ts-expect-error idk why this is happening make it tailwind
                              (wrapperStyle = {
                                  ...wrapperStyle,
                                  ...styles.bottomBarIconActive,
                              })
                            : // @ts-expect-error idk why this is happening make it tailwind
                              (wrapperStyle = {
                                  ...wrapperStyle,
                                  ...styles.bottomBarIconInactive,
                              });
                        return (
                            <div key={item} style={wrapperStyle as React.CSSProperties} onClick={() => setOpenState(item)}>
                                <div
                                    style={{
                                        ...styles.bottomBarIconInside,
                                        height: item === 0 ? 3 : item === 1 ? 7 : 11,
                                    }}
                                />
                            </div>
                        );
                    })}
                    {selectedOrder && customer && (
                        <ArrowTopRightOnSquareIcon className="text-wayste-blue-400 size-6 cursor-pointer" onClick={onShowDetails} />
                    )}
                </div>
            </div>
            {/* Body of bottom bar */}
            <div
                style={{
                    ...styles.bottomBarBody,
                    paddingBottom: openState !== 0 ? 20 : 0,
                    height: openState === 2 ? '80vh' : openState === 1 ? '40vh' : 0,
                }}
            >
                <div
                    style={{
                        borderBottom: 'solid 1px #D8D8D8',
                        display: 'flex',
                        padding: '0px 0px 2px',
                    }}
                >
                    <div className="relative w-full">
                        <Tabs className="sticky top-0 z-10 border-b bg-white">
                            {tabOptions.map((item) => (
                                <Tab key={item}>{item}</Tab>
                            ))}
                            <TabPanel className="p-3">
                                <div className="flex flex-col gap-4">
                                    {selectedOrder?.order && <OrderDetailsLists order={selectedOrder.order} break3 />}
                                    {selectedOrder && (
                                        <>
                                            <hr />
                                            <InternalTicket
                                                entityID={
                                                    selectedOrderType === 'roll-off'
                                                        ? selectedOrder.id
                                                        : selectedOrder.serviceOrder?.serviceGroupingID || ''
                                                }
                                                entityType={selectedOrderType === 'roll-off' ? 'sourgum-order' : 'sourgum-service-grouping'}
                                                readOnly
                                            />
                                        </>
                                    )}
                                </div>
                            </TabPanel>
                            <TabPanel className="p-3">
                                {customer ? (
                                    <div>
                                        <div>
                                            <CustomerDetailsLists customer={customer} />
                                        </div>
                                        <div className="mt-6">
                                            <ContactsListTable customer={customer} addContact={false} />
                                        </div>
                                    </div>
                                ) : null}
                            </TabPanel>
                            {/* Removed Per Dane's Request */}

                            <TabPanel className="p-3">
                                {selectedOrder?.order && receivables && payables ? (
                                    <OrderBillingDetailsLists
                                        receivables={receivables}
                                        payables={payables}
                                        order={selectedOrder.order}
                                        break3
                                    />
                                ) : null}
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsBar;
