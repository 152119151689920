import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Hauler, InternalTicket, Order, Profile } from '@alliance-disposal/transport-types';
import { ManageTags } from '@wayste/sour-components';
import { InternalTicketProvider } from '@wayste/sour-components';
import { Button, Tooltip } from '@wayste/sour-ui';
import { format, isAfter } from 'date-fns';
import type { Entities } from './InternalTicketWorkflow';

const extractTicketEntityInfo = (
    ticket: InternalTicket.SAPInternalTicketTransport,
    entity?: Order.AllianceOrderTransport | Customer.AllianceCustomerTransport | object,
) => {
    if (!entity) return {};
    switch (ticket.entityType) {
        case 'sourgum-order':
            return {
                title: `Order - ${(entity as Order.AllianceOrderTransport).orderNumber}`,
                vendorName: (entity as Order.AllianceOrderTransport).vendorName,
            };
        case 'sourgum-customer':
            return {
                title: `Customer - ${(entity as Customer.AllianceCustomerTransport).customerNumber}`,
                vendorName: null,
            };
        case 'sourgum-vendor':
            return {
                title: `Vendor - ${(entity as Hauler.HaulerWithAapTransport).name}`,
                vendorName: null,
            };
        case 'sourgum-service-grouping':
            return {
                title: `SS - ${(entity as Order.AllianceOrderTransport).orderNumber}`,
                vendorName: null,
            };
        case 'sourgum-lead':
            return {
                title: 'Lead',
                vendorName: null,
            };
        default:
            return {
                title: '',
                vendorName: null,
            };
    }
};

type TicketItemProps = {
    ticket: InternalTicket.SAPInternalTicketTransport;
    entities: Entities;
    onAddEntity?: (entityID: string, entityType: InternalTicket.SourgumInternalTicketRelatedEntity) => void;
    onTicketItemClick?: (ticket: InternalTicket.SAPInternalTicketTransport) => void;
    rosterMap: { [key: string]: Profile.ProfileTransport };
    onRefreshTickets: () => void;
};

export const TicketItem = ({ ticket, entities, onAddEntity, onTicketItemClick, rosterMap, onRefreshTickets }: TicketItemProps) => {
    const client = useWaysteClient();
    const currentUser = client.user().get();
    const [assignedToMeLoading, setAssignedToMeLoading] = useState(false);

    useEffect(() => {
        if (onAddEntity && !entities[ticket.entityID]) {
            onAddEntity(ticket.entityID, ticket.entityType);
        }
    }, []);

    const handleAssignToMe = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAssignedToMeLoading(true);
        e.stopPropagation();
        await client.internalTicket().adminPortal.update(ticket.id, { assignedToProfileID: currentUser.id });
        setAssignedToMeLoading(false);
        onRefreshTickets();
    };

    return (
        <div
            className="mb-2 flex w-full flex-col gap-y-2 rounded bg-white p-2"
            onClick={() => {
                if (onTicketItemClick) onTicketItemClick(ticket);
            }}
        >
            <div className="flex justify-between">
                <div className="font-semibold text-gray-800">{extractTicketEntityInfo(ticket, entities[ticket.entityID])?.title}</div>
                {!ticket.assignedToProfileID && ticket.assignedTeam && (
                    <div className="text-xs">{Profile.SourgumTeamLabels[ticket.assignedTeam]}</div>
                )}
                {ticket.assignedToProfileID && (
                    <Tooltip
                        text={`${rosterMap[ticket.assignedToProfileID]?.firstName} ${rosterMap[ticket.assignedToProfileID]?.lastName}`}
                    >
                        <div className="flex size-6 items-center justify-center rounded-full bg-slate-600 text-xs uppercase text-white">
                            {rosterMap[ticket.assignedToProfileID]?.firstName?.charAt(0)}
                            {rosterMap[ticket.assignedToProfileID]?.lastName?.charAt(0)}
                        </div>
                    </Tooltip>
                )}
            </div>
            <div className="text-sm">
                <span className="text-gray-500">Created:</span> {format(new Date(ticket.metadata.createdAt), 'MM/dd/yy hh:mm a')}
            </div>
            <div className="text-sm">
                <span className="text-gray-500">Updated:</span> {format(new Date(ticket.metadata.lastUpdatedAt), 'MM/dd/yy hh:mm a')}
            </div>
            <InternalTicketProvider>
                <ManageTags readOnly tagIDs={ticket.tags.map((tag) => tag.id)} />
            </InternalTicketProvider>
            <div className="flex justify-between">
                {extractTicketEntityInfo(ticket, entities[ticket.entityID])?.vendorName ? (
                    <div className="text-sm">
                        <span className="text-gray-500">Vendor:</span>{' '}
                        {extractTicketEntityInfo(ticket, entities[ticket.entityID])?.vendorName}
                    </div>
                ) : (
                    <div />
                )}
                {currentUser.id !== ticket.assignedToProfileID ? (
                    <Button className="btn-secondary-text-only" onClick={handleAssignToMe} loading={assignedToMeLoading}>
                        Assign to me
                    </Button>
                ) : (
                    <div />
                )}
            </div>
            {ticket.dueDate && (
                <div className="flex gap-x-2 text-sm">
                    <div className="text-gray-500">Due on:</div>
                    <div className={isAfter(new Date(), new Date(ticket.dueDate)) && ticket.status !== 'SOLVED' ? 'text-red-500' : ''}>
                        {format(new Date(ticket.dueDate), 'EEEE MM/dd/yy')}
                    </div>
                </div>
            )}
        </div>
    );
};
