import React, { useContext, useEffect, useState } from 'react';
import { Order } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Menu, MenuItem } from '@wayste/sour-ui';
import { titleCase } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import routes from '../../utils/routes';

const popperOptionsAll = ['ON_HOLD', 'UNASSIGNED', 'ASSIGNED', 'DELIVERED', 'READY_FULL_PICKUP', 'DUMPED'];

const popperOptions = {
    UNASSIGNED: Order.SourgumOrderStatusLabels.UNASSIGNED,
    ASSIGNED: Order.SourgumOrderStatusLabels.ASSIGNED,
    DELIVERED: Order.SourgumOrderStatusLabels.DELIVERED,
    READY_FULL_PICKUP: Order.SourgumOrderStatusLabels.READY_FULL_PICKUP,
    DUMPED: Order.SourgumOrderStatusLabels.DUMPED,
};

const orderStatusOptions = Object.entries(popperOptions).map((item) => ({
    label: item[1],
    value: item[0] as Order.OrderStatus,
}));

interface Props {
    order: Order.AllianceOrderTransport;
    onChange: (value: Order.OrderStatus) => void;
    navigateOnly?: boolean;
    className?: string;
}

const OrderStatusPopper = ({ order, onChange, navigateOnly, className }: Props) => {
    const history = useHistory();
    const { godModeActive } = useContext(UIContext);
    const [statusColor, setStatusColor] = useState('');
    const { useConfirmationDialog } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();

    useEffect(() => {
        switch (order.status) {
            case 'NEEDS_REVIEW':
                setStatusColor('bg-statuses-NEEDS_REVIEW-primary text-statuses-NEEDS_REVIEW-text');
                break;
            case 'UNASSIGNED':
                setStatusColor('bg-statuses-UNASSIGNED-primary text-statuses-UNASSIGNED-text');
                break;
            case 'ON_HOLD':
                setStatusColor('bg-statuses-ON_HOLD-primary text-statuses-ON_HOLD-text');
                break;
            case 'ASSIGNED':
                setStatusColor('bg-statuses-ASSIGNED-primary text-statuses-ASSIGNED-text');
                break;
            case 'DELIVERED':
                setStatusColor('bg-statuses-DELIVERED-primary text-statuses-DELIVERED-text');
                break;
            case 'READY_FULL_PICKUP':
                setStatusColor('bg-statuses-READY_FULL_PICKUP-primary text-statuses-READY_FULL_PICKUP-text');
                break;
            case 'DUMPED':
                setStatusColor('bg-statuses-DUMPED-primary text-statuses-DUMPED-text');
                break;
            case 'COMPLETED':
                setStatusColor('bg-statuses-COMPLETED-primary text-statuses-COMPLETED-text');
                break;
            default:
                setStatusColor('');
                break;
        }
    }, [order.status]);

    const checkEnabled = (value: string) => {
        const currentIndex = popperOptionsAll.indexOf(order.status);
        const newIndex = popperOptionsAll.indexOf(value);
        if (godModeActive || (newIndex === popperOptionsAll.length - 1 && currentIndex === popperOptionsAll.length - 1)) {
            return true;
        }
        return newIndex < currentIndex || newIndex === currentIndex + 1;
    };

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (order.status === 'NEEDS_REVIEW') {
            e.stopPropagation();
            const orderSwitch = order.switches.find((sw) => sw.toOrder === order.id);
            if (orderSwitch) {
                const result = await getConfirmation({
                    title: 'Switch Order',
                    message: 'This order is part of a Customer Portal Switch Request. Review the pickup request before the new delivery.',
                    confirmText: 'Review Pickup Order',
                });

                if (result) {
                    history.push(routes.orders.details(orderSwitch.fromOrder));
                }
            } else {
                history.push(routes.orders.review(order.id));
            }
            return;
        } else if (navigateOnly) {
            history.push(routes.orders.details(order.id));
            return;
        }
    };

    const getTitle = () => {
        let text = Order.SourgumOrderStatusLabels[order.status];
        if (order.status === 'DUMPED') text = 'enter ticket';
        return text;
    };

    return (
        <Menu
            button={
                <button
                    className={`btn-base h-8 w-28 whitespace-nowrap rounded ${className} ${statusColor}`}
                    type="button"
                    onClick={handleClick}
                >
                    {getTitle()}
                </button>
            }
        >
            {orderStatusOptions.map((item) => (
                <MenuItem
                    key={item.value}
                    active={item.value === order.status}
                    onClick={() => onChange(item.value)}
                    disabled={!checkEnabled(item.value)}
                >
                    {titleCase(item.label)}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default OrderStatusPopper;
