import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { UniversalService } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import { UIContext } from '../../../contexts';
import AutoPaySelection from '../components/AutoPaySelection';

export interface AutoPayDialogProps {
    open: boolean;
    serviceGroupingID: string;
    onClose: () => void;
}
const AutoPayDialog = ({ open, serviceGroupingID, onClose }: AutoPayDialogProps) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const [isLoading, setIsLoading] = useState(false);
    const [serviceGrouping, setServiceGrouping] = useState<UniversalService.ServiceGrouping>();
    const [stripeCustomerID, setStripeCustomerID] = useState('');
    const [autopayEnabled, setAutopayEnabled] = useState(false);
    const [autopayPaymentMethod, setAutopayPaymentMethod] = useState<string | null>(null);

    const fetchData = async () => {
        try {
            const serviceGrouping = await client.universalService().serviceGrouping.fetch(serviceGroupingID);
            setServiceGrouping(serviceGrouping);
            setAutopayEnabled(serviceGrouping.autopayEnabled);
            setAutopayPaymentMethod(serviceGrouping?.autopayPaymentMethod || null);
            const customer = await client.customer().adminPortal.fetch(serviceGrouping.customerID);
            setStripeCustomerID(customer?.stripeId || '');
        } catch {
            console.error('Error fetching stripe customer');
        }
    };

    const handleChange = (autopayEnabled: boolean, sourceID?: string) => {
        setAutopayEnabled(autopayEnabled);
        setAutopayPaymentMethod(sourceID || null);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            await client.universalService().serviceGrouping.update(serviceGroupingID, {
                autopayEnabled,
                autopayPaymentMethod,
                stripeCustomer: stripeCustomerID,
            });
        } catch (error) {
            console.warn('error: ', error);
            showFlash('An error occurred, get an AAP dev', 'warning');
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    useEffect(() => {
        if (!stripeCustomerID) {
            fetchData();
        }
    }, []);

    return (
        <Dialog open={open} className="max-w-4xl" styledTitle={'Auto Pay Settings'} onClose={onClose} showX>
            <div className="flex flex-col p-6">
                <AutoPaySelection
                    stripeCustomerID={stripeCustomerID}
                    onSelection={handleChange}
                    autopayEnabled={autopayEnabled}
                    autopaySourceID={autopayPaymentMethod || ''}
                />
                <Button className="btn-primary" loading={isLoading} onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        </Dialog>
    );
};

export default AutoPayDialog;
