import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { V1 } from '@alliance-disposal/pricing';
import { Customer, Hauler, Invoice, Material, MaterialLabels, Notification, Order, Pricing } from '@alliance-disposal/transport-types';
import { InternalTicket } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Button, Checkbox, DatePicker, Dialog, RadioGroup, TextField, Toggle } from '@wayste/sour-ui';
import {
    extractSizesFromPricingData,
    formatEmailDestination,
    formatServiceAddress,
    formatServiceDate,
    getDateFormat,
    getPrimaryCustomerContact,
    moneyFormatter,
    roundToClosestAllowedSize,
} from '@wayste/utils';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { addDays, format, subDays } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { sendEmail } from '../../../axios/ses';
import { getPublicLinkShortCode } from '../../../axios/wayste-backend';
import { useAuthToken } from '../../../hooks/authhook';
import {
    OrderSwitchTransportWithRelationship,
    ccRate,
    createHaulerPickupEmail,
    createOrderReadyPickupEmailSendGrid,
    createOrderSwitchEmailSendGrid,
    createSourgumPricingSnapshot,
    generateOrderFirstInvoice,
    getCustomerToAndCCEmails,
    getOrderCurrentSwitch,
    paymentMethodsEnums,
    paymentTermsEnums,
    priceTypes,
    routes,
} from '../../../utils';
import { fieldNameMap } from '../../ChangeRequestDialog/ChangeRequestResponse';
import InternalOrderNotes from '../../InternalOrderNotes';
import Loading from '../../Loading';
import OrderPricingBlock, { type OrderPricingBlockFormProps } from '../../OrderPricingBlock';
import ChargeCard2 from '../../card-payment/ChargeCard2';

type FormProps = {
    expectedPickupDate: Date | '';
    rentalEndDate: Date | '';
    sharedPickupNotes?: string;
    dnrToggle: boolean;
    keepExistingHauler: boolean;
    sendCustomerEmail: boolean;
    sendHaulerEmail: boolean;
    newNoteDel: string;
    sharedPickupNotesPrivate?: string;
    newHaulerOnlyNote?: string;
    samePricing: boolean;
    oldPrice: number;
    issueInvoice: string;
    dumpRate: number;
    paymentInfo: {
        paymentReceivedDate: string;
        amount: number;
        paymentMethod: string;
        paymentIdentifier: string;
        stripeChargeID: string;
    } | null;
} & OrderPricingBlockFormProps;

interface Props {
    open: boolean;
    order: Order.AllianceOrderTransport;
    onCancel: () => void;
    changeRequest?: Order.ChangeRequestTransport;
    onChangeRequestUpdated?: (id: string) => void;
}

const OrderReadyPickUp = ({ open, order, onCancel, changeRequest, onChangeRequestUpdated }: Props) => {
    const { token } = useAuthToken();
    const orderHasSwap = order.switches.find((sw) => sw.fromOrder === order.id);
    const client = useWaysteClient();
    const userProfile = client.user().get();
    const { setShowAlert, setShowToast } = useSourContext();
    const [zonePricing, setZonePricing] = useState<Pricing.PricingTransport | null>(null);
    const [zonePricingHauler, setZonePricingHauler] = useState<Pricing.PricingTransport | null>(null);
    const [haulerPricing, setHaulerPricing] = useState<Pricing.PricingTransport | null>(null);
    const [customerInfo, setCustomerInfo] = useState<Customer.AllianceCustomerTransport | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDnrLoading, setIsDnrLoading] = useState(false);
    const [dnrHasLoadedOnce, setDnrHasLoadedOnce] = useState(false); // on true suppresses any refetching
    const [currentSwitch, setCurrentSwitch] = useState<OrderSwitchTransportWithRelationship | null>(null);
    const [swapConstraints, setSwapConstraints] = useState<{
        material: Material[];
        size: Partial<Record<Material, number[]>>;
    }>({
        size: {},
        material: [],
    });
    const [customerPaymentStatuses, setCustomerPaymentStatuses] = useState<{
        openBalance: number | null;
        delinquentBalance: number | null;
        draftBalance: number | null;
    }>({ openBalance: null, delinquentBalance: null, draftBalance: null });

    const prepareFormData = (data: Order.AllianceOrderTransport): FormProps => {
        const newData: FormProps = {
            sendHaulerEmail: true,
            sendCustomerEmail: true,
            dnrToggle: false,
            keepExistingHauler: orderHasSwap ? true : false,
            samePricing: true,
            paymentInfo: null,
            otherLineItems: [],
            newHaulerOnlyNote: '',
            newNoteDel: '',
            issueInvoice: 'NOW',
            adjustedRentalPeriod: data.adjustedRentalPeriod.value,
            rentalEndDate: data.rentalEndDate ? new Date(data.rentalEndDate) : '',
            expectedPickupDate: getDateFormat(data.expectedPickupDate) ? new Date(getDateFormat(data.expectedPickupDate)) : '',
            sharedPickupNotesPrivate: data.sharedPickupNotesPrivate || '',
            sharedPickupNotes: data.sharedPickupNotes || '',
            weightLimit: data.weightLimit?.value || 0,
            expectedSize: data.expectedSize.size,
            material: data.material as Material,
            overage: data.overage || 0,
            priceType: data.priceType as 'ton' | 'flat' | 'yard',
            rentExtensionFee: data.rentExtensionFee || 0,
            taxRate: data.taxRate || 0,
            cc: true,
            oldPrice: 0,
            price: 0,
            tax: false,
            dumpRate: order.dumpRate || 0,
            coupon: '',
        };

        return newData;
    };

    const methods = useForm<FormProps>({
        mode: 'all',
        defaultValues: prepareFormData(order),
    });
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isValid },
        trigger,
    } = methods;
    const [watchRentalEndDate, watchDnrToggle, watchPrice, watchOldPrice, watchOtherLineItems, watchSamePricing] = watch([
        'rentalEndDate',
        'dnrToggle',
        'price',
        'oldPrice',
        'otherLineItems',
        'samePricing',
    ]);

    const pdfToBase64 = (blob: Blob) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                const base64data = reader.result;
                resolve(base64data);
            };
        });
    };

    const handleGetCustomer = async (id: string) => {
        try {
            const customerResponse = await client.customer().adminPortal.fetch(id);
            setCustomerInfo(customerResponse);
        } catch (error) {
            console.warn('handleGetCustomer OrderReadyPickUp Error: ', error);
            setShowToast({
                severity: 'error',
                message: 'An Error Occurred fetching customer information.',
            });
        }
    };

    const handleSwapConstraints = (haulerPricing: Pricing.PricingTransport) => {
        const materials: Material[] = [];
        haulerPricing.pricingData.forEach((pricing) => {
            materials.push(pricing.material as Material);
        });

        const pairs: Partial<Record<Material, number[]>> = {};
        materials.forEach((material) => {
            const haulerSizes = extractSizesFromPricingData(haulerPricing.pricingData, material);
            const allowedSizes: number[] = [];
            haulerSizes.forEach((size) => {
                const acceptableSize = roundToClosestAllowedSize(size, haulerSizes);
                if (acceptableSize) {
                    allowedSizes.push(parseInt(acceptableSize));
                }
            });
            pairs[material] = allowedSizes;
        });

        const constraints = {
            material: materials,
            size: pairs,
        };

        setSwapConstraints(constraints);
        return constraints;
    };

    const handleGetPricingData = async (chargeCC: boolean, taxRate: number) => {
        let newTotal = 0;
        try {
            const results = await client.pricing().adminPortal.location.query(
                {
                    ...order.serviceLocation.coordinates,
                    zip: order.serviceLocation.address.zip,
                    state: order.serviceLocation.address.state,
                },
                order.allianceCustomerID,
            );

            const haulerPricing = results.find((pricing) => pricing.haulerID === order.haulerID && pricing.type === 'SOURGUM_INTERNAL');
            if (haulerPricing) {
                setZonePricingHauler(haulerPricing);
                setHaulerPricing(haulerPricing);
                handleSwapConstraints(haulerPricing);
            } else {
                setShowToast({
                    severity: 'warning',
                    message: 'No pricing found for this hauler',
                });
            }

            const publicPricing = results.filter((item) => item.type === 'PUBLIC');
            if (publicPricing.length === 1) {
                const zone = publicPricing[0];
                setZonePricing(zone);
                if (zone) {
                    const price = V1.calculatePricing(zone, {
                        material: order.material,
                        size: order.expectedSize.size,
                        weightLimit: order?.weightLimit?.value,
                        taxExempt: customerInfo?.taxExempt,
                        taxRate: customerInfo?.taxExempt ? 0 : taxRate,
                        creditCardFee: chargeCC ? ccRate : 0,
                    });
                    newTotal = price.total;
                }
            } else {
                if (publicPricing.length > 1) alert('Get an AAP Dev pricing overlap');
                setZonePricing(null);
            }
        } catch (error) {
            console.warn('handleGetPricing Error: ', error);
            alert('An error occurred, get AAP Dev, touch nothing');
        }
        return newTotal;
    };

    const fetchReceivables = async () => {
        try {
            const data = await client.invoice().adminPortal.receivable.query({
                orderID: order.id,
            });
            return data.results;
        } catch (error) {
            console.error('error: ', error);
            setShowToast({
                severity: 'error',
                message: 'An Error Occurred fetching invoices.',
            });
            return [];
        }
    };

    useEffect(() => {
        if (open) {
            setCurrentSwitch(getOrderCurrentSwitch(order));
            handleGetCustomer(order.allianceCustomerID);
            handleGetPricingData(false, order.taxRate || 0);
        }
    }, [open]);

    const handleDnrToggle = async (value: boolean) => {
        if (value && !dnrHasLoadedOnce) {
            setIsDnrLoading(true);
            const receivableData = await fetchReceivables();
            const oldPrice =
                receivableData?.find((invoice) => invoice?.invoiceDetails.lineItems.find((lineItem) => lineItem?.itemName === 'QP-Haul'))
                    ?.invoiceDetails.total || 0;
            const tax = receivableData[0]?.invoiceDetails?.taxAmount > 0 ? true : false;
            const cc = receivableData[0]?.invoiceDetails?.lineItems.find((lineItem) => lineItem.itemName === 'CC Fee') ? true : false;
            const newPrice = await handleGetPricingData(cc, order.taxRate || 0);
            setValue('oldPrice', oldPrice);
            setValue('tax', tax);
            setValue('cc', cc);
            setValue('price', newPrice);
            trigger(['tax', 'cc', 'price']);
            handleGetDelInvoices(customerInfo?.id as string);
            setDnrHasLoadedOnce(true);
            setIsDnrLoading(false);
        }
    };

    const generatePaymentLink = async (values: FormProps, receivableID: string) => {
        if (
            !values.paymentInfo &&
            (customerInfo?.defaultPaymentSettings?.paymentMethod || order.paymentMethod) !== paymentMethodsEnums.check
        ) {
            const response = await getPublicLinkShortCode(receivableID, token);
            if (response.status === 'error') return null;
            return response?.data?.key;
        }
        return null;
    };

    const handleEmailSend = async (
        order: Order.AllianceOrderTransport,
        values: FormProps,
        hauler: Hauler.HaulerWithAapTransport,
        switchToOrder?: Order.AllianceOrderTransport,
        receivableID?: string,
    ) => {
        let prohibitedItemsList = null;
        prohibitedItemsList = zonePricing?.prohibitedItems;

        // FOR HAULER EMAILS
        if (values.dnrToggle && !switchToOrder?.id) {
            alert('Missing newOrderID, email to hauler does not have correct link. Email still sent');
        }

        const haulerEmailData = createHaulerPickupEmail(
            order,
            hauler,
            !!switchToOrder,
            switchToOrder
                ? {
                      newOrderID: switchToOrder.id,
                      newOrderNumber: switchToOrder?.orderNumber as number,
                      newNoteDel: switchToOrder?.sharedDeliveryNotes || '',
                      material: switchToOrder?.material as Material,
                      newHaulerOnlyNote: switchToOrder?.sharedDeliveryNotesPrivate || '',
                      newSize: switchToOrder?.expectedSize.size,
                  }
                : undefined,
        );

        if (values.sendHaulerEmail) {
            if (!haulerEmailData.toAddress) {
                alert('Missing hauler email, no email sent to hauler.');
            } else {
                try {
                    await sendEmail('send-email', haulerEmailData);
                } catch (error) {
                    setShowToast({
                        severity: 'warning',
                        message: 'Error Sending Hauler Email',
                    });
                }
            }
        }

        // FOR CUSTOMER EMAILS
        let contactEmails;
        try {
            contactEmails = getCustomerToAndCCEmails('dispatch', customerInfo as Customer.AllianceCustomerTransport);
        } catch (error) {
            setShowAlert({
                severity: 'error',
                title: 'Error Sending Customer Email',
                message: (error as Error)?.message || 'An unknown error occurred.',
            });
            return;
        }

        const readyPickupBody = createOrderReadyPickupEmailSendGrid({
            contact: contactEmails.toContact,
            user: userProfile,
            expectedPickupDate: values.expectedPickupDate,
            material: values.material as Material,
            orderNumber: order.orderNumber as number,
            prohibitedItemString: prohibitedItemsList?.join(', '),
            serviceLocation: order.serviceLocation,
            sharedPickupNotes: values.sharedPickupNotes,
        });

        const customerEmailData = {
            destination: formatEmailDestination(contactEmails.to, contactEmails.toContact.firstName || '', contactEmails.cc),
            body: JSON.stringify(readyPickupBody),
            topic: 'ro-ready-pickup',
        };

        if (values.dnrToggle) {
            let payment_link = '';
            if (!receivableID) {
                alert('Missing receivableID, payment_link not sent');
            } else {
                payment_link = await generatePaymentLink(values, receivableID);
            }

            const readyPickupSwapBody = createOrderSwitchEmailSendGrid({
                contact: contactEmails.toContact,
                user: userProfile,
                prohibitedItemString: prohibitedItemsList?.join(', '),
                serviceLocation: order.serviceLocation,
                expectedPickupDate: values.expectedPickupDate,
                material: values.material as Material,
                adjustedRentalPeriod: Number(values.adjustedRentalPeriod),
                expectedSize: Number(values.expectedSize),
                orderTotal: getOrderTotal(values.price, values.otherLineItems),
                sharedDeliveryNotes: `${values.newNoteDel}${values.sharedPickupNotes ? `. On pickup ${values.sharedPickupNotes}` : ''}`,
                overage: values.overage ? Number(values.overage) : '',
                weightLimit: values.weightLimit || '',
                priceType: values.priceType,
                rentExtensionFee: Number(values.rentExtensionFee),
                payment_link: payment_link || '',
            });
            customerEmailData.body = JSON.stringify(readyPickupSwapBody);
            customerEmailData.topic = 'ro-ready-pickup-swap';
        }

        if (customerEmailData.destination && values.sendCustomerEmail) {
            try {
                await client.notification().adminPortal.createInstantNotification({
                    handler: 'sendgrid',
                    topic: customerEmailData.topic,
                    destination: customerEmailData.destination,
                    body: customerEmailData.body,
                });
            } catch (error) {
                setShowToast({
                    severity: 'warning',
                    message: 'Error Sending Customer Email',
                });
            }

            if (values.issueInvoice === 'NOW' && receivableID && customerInfo) {
                let contactEmailsBilling;
                try {
                    contactEmailsBilling = getCustomerToAndCCEmails('billing', customerInfo);
                } catch (error) {
                    setShowAlert({
                        severity: 'error',
                        title: 'Error Sending Customer Cancellation Email',
                        message: (error as Error)?.message || 'An unknown error occurred.',
                    });
                    return;
                }
                const invoicePDFResponse = await client.invoice().adminPortal.pdf.fetch(receivableID);
                const blob = new Blob([invoicePDFResponse], { type: 'application/pdf' });
                const invoiceBlob = ((await pdfToBase64(blob)) as string).split(',')[1];
                const invoiceEmailData: Notification.SendGrid.InvoiceIntermediary = {
                    first_name: contactEmailsBilling.toContact?.firstName || '',
                    receipt_invoice: 'Invoice',
                    invoice_number: `${switchToOrder?.orderNumber} - 1`,
                    order_number: switchToOrder?.orderNumber?.toString() || '',
                    payment_link: '',
                };

                if (invoiceBlob) {
                    try {
                        await client.notification().adminPortal.createInstantNotification({
                            handler: 'sendgrid',
                            topic: 'invoice-intermediary',
                            destination: formatEmailDestination(
                                contactEmailsBilling.to,
                                contactEmailsBilling.toContact.firstName || '',
                                contactEmailsBilling.cc,
                            ),
                            body: JSON.stringify(invoiceEmailData),
                            directAttachments: [
                                {
                                    content: invoiceBlob,
                                    type: 'application/pdf',
                                    filename: `Sourgum Invoice.pdf`,
                                    disposition: 'attachment',
                                },
                            ],
                        });
                    } catch (error) {
                        setShowToast({
                            severity: 'warning',
                            message: 'Error Sending Invoice Email',
                        });
                    }
                } else {
                    console.warn('invoiceBlob did not create. Debug why');
                    alert('Touch nothing and get a SAP dev to inspect console');
                }
            }
        }

        return true;
    };

    const handleUpdateChangeRequest = async (updatedOrder: Order.AllianceOrderUpdateInput) => {
        if (!changeRequest) return;
        const update = {
            id: changeRequest.id,
            changes: changeRequest.changes.map(
                (
                    change: Order.ChangeRequestTransport['changes'][0] extends Order.UpdateChangeRequest['changes'][0]
                        ? Order.ChangeRequestTransport['changes'][0]
                        : never,
                ) => {
                    change.amendment = updatedOrder[change.field as keyof typeof updatedOrder] as string;
                    change.status = 'APPROVED';

                    // convert date to string
                    if (change.field === 'expectedPickupDate' || change.field === 'expectedDeliveryDate') {
                        change.amendment = format(new Date(change.amendment as string), 'yyyy-MM-dd');
                    }

                    // make sure the amendment is different from the original value
                    if (change.newValue === change.amendment) {
                        delete change.amendment;
                    } else {
                        change.status = 'APPROVED_WITH_CHANGES';
                    }

                    if (change.notes === '') {
                        delete change.notes;
                    }

                    return {
                        id: change.id,
                        status: change.status,
                        amendment: change.amendment,
                    };
                },
            ),
        };
        try {
            await client.order().adminPortal.changeRequest.update(update);

            if (onChangeRequestUpdated) {
                onChangeRequestUpdated(update.id);
            }
        } catch (e) {
            console.warn('Error updating change request', e);
        }
        return;
    };

    const onFormSubmit = async (values: FormProps) => {
        setIsLoading(true);
        if (!order.haulerID) {
            setShowToast({
                severity: 'warning',
                message: 'Order does not have a hauler assigned. Please assign a hauler before proceeding.',
            });
            return;
        }
        if (!customerInfo) {
            alert('Missing customerInfo, touch nothing get a SAP dev');
            return;
        }

        const updatedOrder: Order.AllianceOrderUpdateInput = {
            expectedPickupDate: formatServiceDate(values.expectedPickupDate, 'string'),
            requestedPickupDate: order.requestedPickupDate || formatServiceDate(values.expectedPickupDate, 'string'),
            rentalEndDate: formatServiceDate(values.rentalEndDate, 'string'),
            sharedPickupNotes: values.sharedPickupNotes,
            status: 'READY_FULL_PICKUP',
            haulerConfirmedPickup: false,
            sharedPickupNotesPrivate: values.sharedPickupNotesPrivate,
        };

        const hauler = await client.vendorService().adminPortal.fetch(order.haulerID as string);

        let existingSwitchToOrder: Order.AllianceOrderTransport | null = null;

        if (orderHasSwap) {
            existingSwitchToOrder = await client.order().adminPortal.fetch(orderHasSwap.toOrder);
        }

        const foundPricing = haulerPricing?.pricingData.find((pricing) => pricing.material === order.material);
        const pricing = foundPricing ? foundPricing : null;
        const sizePricing = pricing?.sizes ? pricing.sizes.find((item) => +item.size === +order.expectedSize.size) : null;

        if (!haulerPricing || !sizePricing || !pricing) {
            setShowToast({
                severity: 'warning',
                message: 'No hauler pricing found is area. Payables will have to be manually entered.',
            });
        }

        if (!haulerPricing?.haulerID) {
            setIsLoading(false);
            setShowToast({
                severity: 'warning',
                message: 'No hauler found for this order. Please check with engineering.',
            });
            return;
        }

        if (!values.material) {
            setIsLoading(false);
            setShowToast({
                severity: 'warning',
                message: 'Material is required',
            });
            return;
        }

        const haulerPricingSnapshot: Order.HaulerPricingSnapshotTransport = {
            dump: Math.round(sizePricing?.dump || 0),
            over: Math.round(sizePricing?.over || 0),
            haul: Math.round(sizePricing?.haul || 0),
            size: Number(sizePricing?.size) || Number(values.expectedSize),
            tonLimit: { value: sizePricing?.tonLimit || 0, unit: 'TONS' },
            rentalPeriod: haulerPricing?.rentalPeriod ? haulerPricing?.rentalPeriod : { value: 0, unit: 'DAYS' },
            rentalExtensionFee: haulerPricing?.rentExtensionFee || 0,
            priceType: pricing?.type || priceTypes.ton,
            paymentTerm: paymentTermsEnums.net30,
            paymentMethod: hauler?.defaultPaymentMethod || paymentMethodsEnums.check,
        };

        // Values used for creating new order & need to be accessible outside of the if statement
        let updatedOrderResponse;
        let switchToOrder: Order.AllianceOrderTransport | undefined = undefined;
        let receivableID = undefined;

        // logic for existing swaps, usually create via the customer portal
        if (
            !values.dnrToggle &&
            existingSwitchToOrder &&
            (existingSwitchToOrder.status === 'NEEDS_REVIEW' || existingSwitchToOrder.status === 'UNASSIGNED')
        ) {
            if (values.keepExistingHauler) {
                switchToOrder = await client.order().adminPortal.update(existingSwitchToOrder.id, {
                    status: 'ASSIGNED',
                    haulerPricingSnapshot,
                    haulerID: order.haulerID,
                });
            } else {
                // split the switch
                await client.order().adminPortal.deleteSwitch(orderHasSwap!.id);
            }
        }

        // logic for new switches, usually create via the web app
        if (values.dnrToggle) {
            // find pricing that matches the size and material

            const primaryContact = getPrimaryCustomerContact(customerInfo);
            const newOrderObj: Order.AllianceOrderCreateInput = {
                customerCompanyName: customerInfo.companyName || undefined,
                vendorName: hauler.name,
                customerAccountNumber: customerInfo?.customerNumber.toString() || undefined,
                customerName: `${primaryContact?.firstName} ${primaryContact?.lastName}`,
                switchFromOrderID: order.id,
                switchType: 'DUMP_AND_RETURN',
                checkoutType: 'AGENT',
                haulerID: order.haulerID,
                poNumber: order.poNumber,
                status: 'UNASSIGNED',
                allianceCustomerID: order.allianceCustomerID,
                requestedDeliveryDate: formatServiceDate(values.expectedPickupDate, 'string'),
                originalRentalPeriod: {
                    value: Number(values.adjustedRentalPeriod),
                    unit: 'DAYS',
                },
                expirationDate: addDays(new Date(values.expectedPickupDate), Number(values.adjustedRentalPeriod)).toISOString(),
                sharedDeliveryNotes: values.newNoteDel,
                sharedPickupNotes: '',
                sharedDeliveryNotesPrivate: values.newHaulerOnlyNote,
                sharedPickupNotesPrivate: '',
                material: values.material,
                recurringOnCall: order.recurringOnCall || false,
                ccRate: ccRate,
                haulerDumpRate: order.haulerDumpRate || 0,
                haulerHaulRate: order.haulerHaulRate || 0,
                overage: values.priceType === 'ton' ? Number(values.overage) : null,
                dumpRate: values.dumpRate || 0,
                rentExtensionFee: values.rentExtensionFee || 0,
                taxRate: values.taxRate,
                priceType: values.priceType,
                paymentMethod: customerInfo?.defaultPaymentSettings?.paymentMethod || order.paymentMethod,
                haulerPricingSnapshot: haulerPricingSnapshot,
                paymentTerm: customerInfo?.defaultPaymentSettings?.paymentTerm || order.paymentTerm,
                serviceLocation: order.serviceLocation,
                requestedSize: { size: Number(values.expectedSize), type: 'OPEN_TOP' },
                weightLimit:
                    values.priceType === 'ton'
                        ? {
                              value: Number(values.weightLimit),
                              unit: 'TONS',
                          }
                        : null,
                sourgumPricingSnapshot: createSourgumPricingSnapshot(values, zonePricing),
            };
            try {
                const newOrderResponseInitial = await client.order().adminPortal.create(newOrderObj);
                // Have to refetch because backend isn't sending orderNumber back with creation
                switchToOrder = await client.order().adminPortal.fetch(newOrderResponseInitial.id);
            } catch (error) {
                console.warn('createOrder orderError: ', error);
                setShowToast({
                    severity: 'error',
                    message: 'An Error Occurred Creating Order',
                });
                setIsLoading(false);
                return;
            }

            const paymentSettingTerm = customerInfo?.defaultPaymentSettings.paymentTerm || order.paymentTerm;
            let newInvoice = undefined;
            if (paymentSettingTerm) {
                newInvoice = generateOrderFirstInvoice(
                    Number(values.price),
                    values.priceType,
                    Number(values.weightLimit),
                    Number(values.expectedSize),
                    Number(order.dumpRate),
                    values.tax,
                    customerInfo?.taxExempt ? 0 : values.taxRate,
                    values.cc,
                    paymentSettingTerm,
                    values.material as Material,
                    values.otherLineItems,
                    values.issueInvoice as 'NOW' | 'DRAFT',
                );
            }

            if (newInvoice && switchToOrder.id && switchToOrder.orderNumber) {
                const createObj: Invoice.ReceivableCreateTransport = {
                    customerID: order?.allianceCustomerID,
                    customerName: order.customerName || undefined,
                    customerCompanyName: order.customerCompanyName || undefined,
                    invoiceDetails: {
                        ...newInvoice,
                        orderID: switchToOrder.id,
                        issueDate: newInvoice.issueDate ? newInvoice.issueDate.toISOString() : undefined,
                        dueDate: newInvoice.dueDate ? newInvoice.dueDate.toISOString() : undefined,
                        orderNumber: switchToOrder.orderNumber.toString(),
                        orderServiceLocation: order.serviceLocation,
                    },
                };

                let invoiceResponse = null;
                try {
                    invoiceResponse = await client.invoice().adminPortal.receivable.create(createObj);
                } catch (error) {
                    console.warn('createReceivable error: ', error);
                    setShowToast({
                        severity: 'error',
                        message: 'An Error Occurred while Creating Receivable',
                    });
                    return;
                }

                receivableID = invoiceResponse?.id;
                if (values.paymentInfo) {
                    try {
                        await client.invoice().adminPortal.payment.create(invoiceResponse?.invoiceDetails.id, values.paymentInfo);
                    } catch (error) {
                        console.warn('paymentResponse error: ', error);
                        setShowToast({
                            severity: 'error',
                            message: 'An Error Occurred while Creating Payment',
                        });
                    }
                }
            } else {
                setShowToast({
                    severity: 'error',
                    message: 'An error occurred get a SAP. No Invoice created',
                });
            }
        }

        try {
            updatedOrderResponse = await client.order().adminPortal.update(order.id, updatedOrder);
        } catch (error) {
            console.warn('orderResponse error: ', error);
            setShowToast({
                severity: 'error',
                message: 'An Error Occurred while Updating Order',
            });
            return;
        }

        await handleEmailSend(updatedOrderResponse, values, hauler, switchToOrder, receivableID);

        if (changeRequest) {
            await handleUpdateChangeRequest(updatedOrder);
        }
        setShowToast({
            severity: 'success',
            message: 'Order Successfully Updated',
        });

        onCancel();
    };

    const getOrderTotal = (price: string | number, otherLineItems: Invoice.LineItemInputTransport[]): number => {
        let total = Number(price);
        if (otherLineItems && otherLineItems.length > 0) {
            otherLineItems.forEach((item) => {
                total = total + Number(item.totalPrice);
            });
        }
        return total;
    };

    const handleGetDelInvoices = async (customerID: string) => {
        if (customerPaymentStatuses.openBalance !== null) {
            return;
        }
        const response = await client.invoice().adminPortal.receivable.query({ customerID, paidInFull: false, void: false, limit: 1000 });
        const results = response.results;
        const inDraft = results.filter((invoice) => invoice.invoiceDetails.status === 'DRAFT');
        const inDelinquent = results.filter(
            (invoice) => invoice.invoiceDetails.dueDate && new Date(invoice.invoiceDetails.dueDate) <= new Date(),
        );
        const openBalance = results.reduce((prevValue, currentValue) => {
            return prevValue + currentValue.invoiceDetails.remainingBalance;
        }, 0);
        const delinquentBalance = inDelinquent.reduce((prevValue, currentValue) => {
            return prevValue + currentValue.invoiceDetails.remainingBalance;
        }, 0);
        const draftBalance = inDraft.reduce((prevValue, currentValue) => {
            return prevValue + currentValue.invoiceDetails.remainingBalance;
        }, 0);
        setCustomerPaymentStatuses({ openBalance, delinquentBalance, draftBalance });
    };

    return (
        <Dialog open={open} onClose={onCancel} className="max-w-screen-2xl" styledTitle="Order Ready For Pick Up">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    {isDnrLoading && <Loading fullScreen />}
                    <div className="flex flex-col">
                        <div>Pick up information for:</div>
                        <div style={{ marginBottom: 32, marginTop: 7 }}>{formatServiceAddress(order.serviceLocation.address)}</div>
                        {changeRequest && (
                            <div>
                                <div>Customer Request:</div>
                                <div style={{ marginBottom: 32, marginTop: 7 }}>
                                    {changeRequest.changes
                                        .filter((change) => change.field !== 'status')
                                        .map((change) => {
                                            return (
                                                <div>
                                                    {fieldNameMap[change.field as keyof typeof fieldNameMap]}:{' '}
                                                    {String(change.field).includes('Date')
                                                        ? format(new Date(change.newValue), 'MM/dd/yyyy')
                                                        : change.newValue}{' '}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        <Controller
                            name="expectedPickupDate"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Pickup date is required',
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <DatePicker
                                    label="Pickup date"
                                    required
                                    minDate={subDays(new Date(), 1)}
                                    value={field.value}
                                    closeOnSelect
                                    error={fieldState.error}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        if (!watchRentalEndDate && value) setValue('rentalEndDate', value);
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="rentalEndDate"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Rental end date is required',
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <DatePicker
                                    label="Rental extension end date"
                                    required
                                    value={field.value}
                                    closeOnSelect
                                    helperText="No days will be added to the rental extension fee after this date. This date can be within the order's rental period."
                                    error={fieldState.error}
                                    onChange={(value) => field.onChange(value)}
                                />
                            )}
                        />
                        <div className="md:col-span-2">
                            <Controller
                                name="sharedPickupNotesPrivate"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="Pickup hauler only notes"
                                        helperText={`Notes only the hauler will see when we send emails. Previous hauler only note: ${order.sharedDeliveryNotesPrivate}`}
                                        error={fieldState.error}
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="md:col-span-2">
                            <Controller
                                name="sharedPickupNotes"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label="Pickup notes"
                                        error={fieldState.error}
                                        inputProps={{
                                            ...field,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        {order.internalNotes.length > 0 && (
                            <div className="md:col-span-2">
                                <InternalOrderNotes order={order} hideAddButton />
                            </div>
                        )}
                        {customerInfo?.notes && (
                            <p className="md:col-span-2">
                                <b>Customer Notes:</b> {customerInfo.notes}
                            </p>
                        )}
                        {/* TODO fetch internal tickets */}
                        <div className="md:col-span-2">
                            <InternalTicket entityID={order.id} entityType={'sourgum-order'} readOnly />
                        </div>
                        <Controller
                            name="sendHaulerEmail"
                            control={control}
                            render={({ field }) => (
                                <Toggle value={field.value} label="Send hauler email" onChange={(value) => field.onChange(value)} />
                            )}
                        />
                        <Controller
                            name="sendCustomerEmail"
                            control={control}
                            render={({ field }) => (
                                <Toggle value={field.value} label="Send customer email" onChange={(value) => field.onChange(value)} />
                            )}
                        />
                        <div className="md:col-span-2">
                            {orderHasSwap ? (
                                <div className="flex flex-col gap-2">
                                    <p className="inline-flex items-center gap-1">
                                        A switch already exists for this order.
                                        <a
                                            className="text-primary-400 flex items-center gap-1"
                                            href={routes.orders.details(orderHasSwap!.toOrder)}
                                            target="_blank"
                                        >
                                            View
                                            <ArrowTopRightOnSquareIcon className="size-4" />
                                        </a>
                                    </p>
                                    <Controller
                                        name="keepExistingHauler"
                                        control={control}
                                        render={({ field }) => (
                                            <Toggle
                                                value={field.value}
                                                label={`Keep swap assigned to ${order.vendorName || 'current hauler'}?`}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                            />
                                        )}
                                    />
                                    {!watch('keepExistingHauler') && (
                                        <div className="text-sm text-gray-500">
                                            <i>
                                                The switch will be split and the new order will be left as Needs Review. You will need to
                                                assign the new order manually.
                                            </i>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <Controller
                                    name="dnrToggle"
                                    control={control}
                                    render={({ field }) => (
                                        <Toggle
                                            value={field.value}
                                            label="Dump and return"
                                            onChange={(value) => {
                                                field.onChange(value);
                                                handleDnrToggle(value);
                                                setValue('keepExistingHauler', false);
                                            }}
                                            disabled={Boolean(orderHasSwap)}
                                        />
                                    )}
                                />
                            )}
                        </div>
                        {watchDnrToggle && (
                            <>
                                <div className="md:col-span-2">
                                    <Controller
                                        name="newHaulerOnlyNote"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Delivery hauler only notes"
                                                error={fieldState.error}
                                                helperText={`Notes only the hauler will see when we send emails. Previous delivery hauler notes: ${order.sharedDeliveryNotesPrivate}`}
                                                inputProps={{
                                                    ...field,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="md:col-span-2">
                                    <Controller
                                        name="newNoteDel"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: watchDnrToggle,
                                                message: 'Delivery notes are required',
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="New delivery notes"
                                                error={fieldState.error}
                                                helperText={`Old delivery note: ${order.sharedDeliveryNotes}`}
                                                required={watchDnrToggle}
                                                inputProps={{
                                                    ...field,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="md:col-span-2">
                                    <Controller
                                        name="samePricing"
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox
                                                label="Same size and material"
                                                inputProps={{
                                                    checked: field.value,
                                                    onChange: (e) => {
                                                        field.onChange(e.currentTarget.checked);
                                                        setValue('otherLineItems', []);
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                    <div>
                                        {order.expectedSize.size} YD, {MaterialLabels[order.material]}, Old Price:{' '}
                                        {moneyFormatter(Number(watchOldPrice))}, Current Price:
                                        {moneyFormatter(Number(watchPrice))}
                                    </div>
                                </div>
                                {zonePricingHauler &&
                                    (!zonePricingHauler?.sourgumApproved ||
                                        !zonePricingHauler.pricingData.find((material) => material.material === order.material)
                                            ?.sourgumApproved) && (
                                        <div className="md:col-span-2">
                                            <div className="rounded bg-red-50 p-2.5 text-red-600">
                                                Warning hauler's pricing has not been approved by Sourgum.
                                            </div>
                                        </div>
                                    )}
                            </>
                        )}
                    </div>

                    {watchDnrToggle && !watchSamePricing && (
                        <div className="mt-4">
                            <OrderPricingBlock
                                zonePricing={zonePricing}
                                existingCustomer={customerInfo}
                                swapConstraints={swapConstraints}
                                passedSetOnce={true}
                                showSwitchText={true}
                            />
                        </div>
                    )}
                    {watchDnrToggle && customerInfo?.defaultPaymentSettings?.paymentTerm !== paymentTermsEnums.onCharge && (
                        <div className="flex flex-col gap-4">
                            <div className="mt-4">
                                <b>Issue Invoice</b>
                            </div>
                            <div>
                                <i>
                                    If you want to make edits to this orders invoice before sending it to the customer, click <b>Draft</b>
                                </i>
                            </div>
                            <div className="flex">
                                <Controller
                                    name="issueInvoice"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            options={[
                                                { label: 'Now', value: 'NOW' },
                                                { label: 'Draft', value: 'DRAFT' },
                                            ]}
                                            value={field.value}
                                            onChange={(value) => field.onChange(value)}
                                            name="issueInvoice"
                                            className="flex gap-4"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                    {watchDnrToggle && customerInfo && (
                        <div className="mt-4 grid grid-cols-3 gap-2 text-sm">
                            <div className="underline">Draft Balance</div>
                            <div className="underline">Open Balance</div>
                            <div className="underline">Delinquent Balance</div>
                            <div>{moneyFormatter(customerPaymentStatuses.draftBalance, { undefinedBehavior: 'Loading...' })}</div>
                            <div>{moneyFormatter(customerPaymentStatuses.openBalance, { undefinedBehavior: 'Loading...' })}</div>
                            <div>{moneyFormatter(customerPaymentStatuses.delinquentBalance, { undefinedBehavior: 'Loading...' })}</div>
                        </div>
                    )}
                    {watchDnrToggle &&
                        customerInfo &&
                        ((customerInfo?.defaultPaymentSettings?.paymentMethod || order.paymentMethod) === paymentMethodsEnums.creditCard ? (
                            <div style={{ marginTop: 32 }}>
                                <ChargeCard2
                                    total={getOrderTotal(watchPrice, watchOtherLineItems)}
                                    showChargeOption={true}
                                    customer={customerInfo}
                                    onChargeSuccessful={(stripeChargeID, last4) => {
                                        const paymentInfo = {
                                            paymentReceivedDate: new Date().toISOString(),
                                            amount: getOrderTotal(watchPrice, watchOtherLineItems),
                                            paymentMethod: (customerInfo?.defaultPaymentSettings?.paymentMethod ||
                                                order.paymentMethod) as string,
                                            paymentIdentifier: last4,
                                            stripeChargeID,
                                        };
                                        setValue('paymentInfo', paymentInfo);
                                    }}
                                    onSaveSuccessful={() => {
                                        alert('Card saved successfully');
                                    }}
                                />
                            </div>
                        ) : (
                            <div style={{ marginTop: 32 }}>Payment method is Check</div>
                        ))}
                    <div className="mt-4 flex justify-end">
                        <button className="btn-dark-grey-outlined mr-5" onClick={onCancel} disabled={isLoading} type="button">
                            Cancel
                        </button>
                        <Button className="btn-primary" disabled={!isValid} type="submit" loading={isLoading}>
                            Save
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default OrderReadyPickUp;
