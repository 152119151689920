import { Loading as SourLoading } from '@wayste/sour-ui';

const Loading = ({ fullScreen }: { fullScreen?: boolean }) => {
    return (
        <div className={fullScreen ? 'fixed inset-0 z-[1375] bg-black bg-opacity-50 backdrop-blur-sm' : 'container mx-auto p-6'}>
            <SourLoading size="h-12 w-12" className="absolute inset-0 m-auto" />
        </div>
    );
};

export default Loading;
