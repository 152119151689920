import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { InternalTicket } from '@wayste/sour-components';
import { SocketError, SourSocket } from '@wayste/sour-socket';
import { useFlash } from '../../hooks/useFlash';
import { leadTypesEnums } from '../../utils/shared-types';
import Loading from '../Loading';
import ContactedDetails from './ContactedDetails';
import LeadRODetails from './LeadRODetails';
import LeadUSDetails from './LeadUSDetails';
import MarketingDetails from './MarketingDetails';
import ROQuoteDetails from './ROQuoteDetails';
import USQuoteDetails from './USQuoteDetails';
import USVendorQuoteDetails from './USVendorQuoteDetails';

export interface LeadDetailsProps {
    id: string;
    onLeadFetched?: (lead: Customer.AllianceLeadTransport | null) => void;
}

const LeadDetails = ({ id, onLeadFetched }: LeadDetailsProps) => {
    const client = useWaysteClient();
    const { showFlash } = useFlash();

    // STATE
    const [lead, setLead] = useState<Customer.AllianceLeadTransport | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        let subscription: SourSocket<Customer.AllianceLeadTransport, never>;
        if (id) {
            setIsLoading(true);
            subscription = client.customer().adminPortal.leads.subscription.fetch(id);

            subscription.receive.subscribe({
                next: (lead) => {
                    setLead(lead);
                    if (onLeadFetched) onLeadFetched(lead);
                    setIsLoading(false);
                },
                error: (error) => {
                    showFlash((error as SocketError).additionalInfo || 'Error fetching lead');
                    setIsLoading(false);
                },
            });
        } else {
            setLead(null);
            if (onLeadFetched) onLeadFetched(null);
        }

        return () => {
            if (subscription) subscription.unsubscribe();
        };
    }, [id]);

    const getDetailsContent = () => {
        switch (lead?.type) {
            case leadTypesEnums.rollOff:
                return (
                    <>
                        <LeadRODetails rawLead={lead} />
                        <InternalTicket entityID={lead.id} entityType="sourgum-lead" />
                        <ROQuoteDetails rawLead={lead} />
                    </>
                );
            case leadTypesEnums.commercial:
            case leadTypesEnums.residential:
            case leadTypesEnums.portableToilet:
                return (
                    <>
                        <LeadUSDetails rawLead={lead} />
                        <InternalTicket entityID={lead.id} entityType="sourgum-lead" />
                        <USQuoteDetails rawLead={lead} />
                        <USVendorQuoteDetails rawLead={lead} />
                    </>
                );
            default:
                return <div>Missing Lead Type</div>;
        }
    };

    if (isLoading) return <Loading />;

    if (!lead) return <div>Lead does not exist</div>;

    return (
        <div className="flex flex-col gap-y-5 pb-5">
            {getDetailsContent()}
            <ContactedDetails lead={lead} />
            <MarketingDetails lead={lead} />
        </div>
    );
};

export default LeadDetails;
