import { useEffect, useState, useCallback } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import type { UniversalService } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { TanDataGrid } from '@wayste/sour-ui';
import { formatISODateString, moneyFormatter, sanitizeFromPayload } from '@wayste/utils';
import { TrashIcon } from '@heroicons/react/24/solid';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import UniversalServicesCancelDialog from '../components/UniversalServicesCancelDialog/UniversalServicesCancelDialog';
import UniversalServicesStatusPopper from '../components/UniversalServicesStatusPopper';

type Order = {
    serviceOrder: UniversalService.ServiceOrder;
    orderNumber?: string;
    servicePeriod?: string;
    serviceType?: string;
    sku?: string;
    status?: string; // Changing `status` type to string to match the type UniversalServicesStatusPopper expects
    frequency?: string;
    vendor?: string;
    totalRecurringRate?: string;
    actions?: undefined;
    type: 'subscription' | 'oneTime';
    canceled?: boolean;
    subscriptionActive?: boolean | undefined;
    vendorCost?: string;
};

interface ServiceOrdersListTableProps {
    serviceGrouping?: UniversalService.ServiceGrouping | undefined;
    serviceOrders: UniversalService.ServiceOrder[];
    deletable?: boolean;
    onRowClicked: (order: UniversalService.ServiceOrder) => void;
    dodgyLocalCachingForVendorCost: Map<string, number>;
}

const ServiceOrdersListTable = ({ serviceGrouping, serviceOrders, dodgyLocalCachingForVendorCost, deletable = true, onRowClicked }: ServiceOrdersListTableProps) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();

    const getRowFromServiceOrder = useCallback((serviceOrder: UniversalService.ServiceOrder) => {
        const order: Order = {
            serviceOrder: serviceOrder,
            orderNumber: serviceOrder.fullOrderNumber,
            servicePeriod: `${formatISODateString(serviceOrder.startDate?.toString())} - ${
                serviceOrder.endDate ? formatISODateString(serviceOrder.endDate?.toString()) : ''
            }`,
            serviceType: serviceOrder.serviceType.family.name,
            sku: serviceOrder.serviceType.name,
            status: serviceOrder.status, // Assuming `status` is a string field in `serviceOrder`
            frequency: serviceOrder?.serviceFrequency + ' per ' + serviceOrder?.serviceFrequencyUnit,
            type: serviceOrder.subscriptionDetails ? 'subscription' : 'oneTime',
            canceled: !!serviceOrder.cancellationDetails,
            subscriptionActive: serviceOrder.subscriptionDetails ? serviceOrder.subscriptionDetails.active : undefined,
            totalRecurringRate: moneyFormatter(
                serviceOrder.events
                    .filter((event) => event.eventType === 'synthetic' || event.eventType === 'subscription-recurring')
                    .reduce((e, event) => (e + event.unitPrice * event.priceQuantity), 0),
            ),
            vendorCost: moneyFormatter(
                dodgyLocalCachingForVendorCost.get(serviceOrder.serviceType?.id ?? '') ?? 0
            ),
            vendor: serviceOrder.vendorName,
        };

        return order;
    }, [dodgyLocalCachingForVendorCost]);

    const [cancelOpen, setCancelOpen] = useState<boolean>(false);
    const [cancelOrderID, setCancelOrderID] = useState<string>('');
    const [cancelOrderType, setCancelOrderType] = useState<'subscription' | 'oneTime'>('oneTime');
    const [data, setData] = useState<Order[]>([]);

    useEffect(() => {
        if (!serviceOrders?.length) {
            setData([]);
            return;
        }
        
        setData(
            serviceOrders
                .sort((a, b) => (b.startDate ?? '').localeCompare(a.startDate ?? ''))
                .map((serviceOrder) => getRowFromServiceOrder(serviceOrder)),
        );
    }, [serviceOrders, dodgyLocalCachingForVendorCost, getRowFromServiceOrder]);

    const columnHelper = createColumnHelper<Order>();
    const columns = [
        columnHelper.accessor('orderNumber', {
            header: 'Order Number',
            cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: (info) => <UniversalServicesStatusPopper display="label" serviceOrder={info.row.original.serviceOrder} />,
        }),
        columnHelper.accessor('servicePeriod', {
            header: 'Service Period',
            cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
        }),
        columnHelper.accessor('serviceType', {
            header: 'Service Type',
            cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
        }),
        columnHelper.accessor('sku', {
            header: 'SKU',
            cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
        }),
        columnHelper.accessor('frequency', {
            header: 'Frequency',
            cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
        }),
        columnHelper.accessor('vendor', {
            header: 'Vendor',
            cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
        }),
        columnHelper.accessor('totalRecurringRate', {
            header: 'Rate',
            cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
        }),
        columnHelper.accessor('vendorCost', {
            header: 'Vendor Cost',
            cell: (info) => <div className="whitespace-nowrap">{info.getValue()}</div>,
        }),
        columnHelper.accessor('actions', {
            header: '',
            cell: (info) => (
                <div className="float-middle flex align-middle">
                    {info.row.original.type === 'subscription' ? (
                        <>
                            {deletable && info.row.original.subscriptionActive === true && (
                                <TrashIcon
                                    className="mr-1 size-5 cursor-pointer text-red-500"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCancelOpen(true);
                                        setCancelOrderID(info.row.original.serviceOrder.id);
                                        setCancelOrderType(info.row.original.type);
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {deletable && info.row.original.canceled === false && (
                                <TrashIcon
                                    className="mr-1 size-5 cursor-pointer text-red-500"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCancelOpen(true);
                                        setCancelOrderID(info.row.original.serviceOrder.id);
                                        setCancelOrderType(info.row.original.type);
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
            ),
        }),
    ] as ColumnDef<Order, string>[];

    const handleDelete = async (cancelServiceOrderPayload: UniversalService.CancellationDetails) => {
        let serviceOrder = serviceOrders.find((order) => order.id === cancelOrderID);

        if (!serviceOrder) return;
        if (!serviceGrouping) return;
        serviceOrder = sanitizeFromPayload(serviceOrder, 'accountManagerID');
        if (cancelOrderType === 'oneTime') {
            const updatePayload: UniversalService.ServiceOrderUpdate = {
                ...serviceOrder,
                vendorID: serviceOrder?.vendorID || undefined,
                cancellationDetails: cancelServiceOrderPayload,
            };

            try {
                await client.universalService().serviceGrouping.serviceOrder.update(serviceGrouping.id, serviceOrder.id, updatePayload);

                setShowToast({ message: 'Service Order Successfully Cancelled', severity: 'success' });
                setCancelOpen(false);
            } catch (error) {
                console.warn(error);
                setShowToast({ message: 'An Error Occurred Cancelling Order', severity: 'error' });
            }
        } else {
            if (!serviceOrder.subscriptionDetails) return;

            const updatePayload: UniversalService.ServiceOrderUpdate = {
                ...serviceOrder,
                vendorID: serviceOrder?.vendorID || undefined,
                cancellationDetails: cancelServiceOrderPayload,
                subscriptionDetails: {
                    ...serviceOrder.subscriptionDetails,
                    endDate: cancelServiceOrderPayload.date,
                    active: false,
                },
            };

            try {
                await client.universalService().serviceGrouping.serviceOrder.update(serviceGrouping.id, serviceOrder.id, updatePayload);
                setShowToast({ message: 'Subscription Successfully Cancelled', severity: 'success' });
                setCancelOpen(false);
            } catch (error) {
                console.warn(error);
                setShowToast({ message: 'An Error Occurred Cancelling Subscription', severity: 'error' });
            }
        }
    };

    return (
        <>
            <UniversalServicesCancelDialog
                variant="delete"
                type={cancelOrderType}
                cancelOpen={cancelOpen}
                setCancelOpen={setCancelOpen}
                onCancel={handleDelete}
            />
            <TanDataGrid
                data={data}
                columns={columns as ColumnDef<Order>[]}
                className="-m-5"
                onRowClick={(row) => onRowClicked(row.original.serviceOrder)}
            />
        </>
    );
};

export default ServiceOrdersListTable;
